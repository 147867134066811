<template>
  <YLayoutBodyMenu>
    <el-card style="min-height: 45rem">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form label-width="10rem" style="padding: 2rem;width: 30rem">
            <el-form-item label="阿里AccessKeyID">
              <el-input v-model="form.sns_ali_access_key_id"></el-input>
            </el-form-item>
            <el-form-item label="阿里AccessKeySecret">
              <el-input v-model="form.sns_ali_access_secret"></el-input>
            </el-form-item>
            <el-form-item label="阿里短信签名">
              <el-input v-model="form.sns_ali_sign_name"></el-input>
            </el-form-item>
            <el-form-item label="验证码阿里短信模版ID">
              <el-input v-model="form.sns_ali_tpl_code"></el-input>
              <div class="y-desc">验证码变量 ：${number}</div>
              <div class="y-desc">模版示例 ：验证码${number}，打死不告诉别人！</div>
            </el-form-item>
            <el-form-item label="佣金到账阿里短信模版ID">
              <el-input v-model="form.sns_ali_tpl_award"></el-input>
              <div class="y-desc">金额变量 ：${fee}</div>
              <div class="y-desc">模版示例 ：佣金到账${fee}元！</div>
            </el-form-item>
            <el-form-item label="级别变动阿里短信模版ID">
              <el-input v-model="form.leve_change_tpl"></el-input>
              <div class="y-desc">文案变量 ：${copy}</div>
              <div class="y-desc">模版示例 ：恭喜${copy}，如有疑问请联系客服！</div>
            </el-form-item>
            <el-form-item>
              <el-button @click="submit" type="primary">保存</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>

    </el-card>
  </YLayoutBodyMenu>
</template>

<script>
import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";

export default {
  name: "sns",
  components: {
    YLayoutBodyMenu
  },
  data() {
    return {
      form: {
        sns_ali_access_key_id: "",
        sns_ali_access_secret: "",
        sns_ali_sign_name: "",
        sns_ali_tpl_code: "",
        sns_ali_tpl_award: "",
        leve_change_tpl: "",
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$u.api.system.sms().then(res => {
        this.form = res;
      })
    },
    submit() {
      this.$u.api.system.smsEdit(this.form).then(() => {
        this.$message.success("操作成功");
      })
    }
  }
}
</script>

<style scoped>

</style>